import burger from "../../assets/icons/menu.png";
import close from "../../assets/icons/close.png";
import { Mobile, Burger } from "./header-mobile.styles";

import { useDispatch, useSelector } from "react-redux";
import { selectDropdownMenuOpened } from "./../../redux/menu-dropdown/menu-dropdown.selectors";
import { toggleDropdownMenu } from "./../../redux/menu-dropdown/menu-dropdown.actions";

function HeaderMobile() {
  const opened = useSelector(selectDropdownMenuOpened);

  const dispatch = useDispatch();
  const toggleOpened = () => dispatch(toggleDropdownMenu);

  return (
    <Mobile>
      <Burger opened={opened} onClick={toggleOpened}>
        <div>
          {opened ? (
            <img src={close} alt="menu" />
          ) : (
            <img src={burger} alt="menu" />
          )}
        </div>
      </Burger>
    </Mobile>
  );
}

export default HeaderMobile;
