import NewsActionTypes from "./news.types";

export const fetchCollectionsStart = () => ({
    type: NewsActionTypes.FETCH_NEWS_START
})

export const fetchCollectionsSuccess = newsMap => ({
    type: NewsActionTypes.FETCH_NEWS_SUCCESS,
    payload: newsMap
})

export const fetchCollectionsFailure = errorMessage => ({
    type: NewsActionTypes.FETCH_NEWS_FAILURE,
    payload: errorMessage
})

/* export const fetchCollectionsStartAsync = () => {
    return dispatch => {
        const collectionRef = firestore.collection('news');
        dispatch(fetchCollectionsStart())

        collectionRef.get()
            .then(
                snapshot => {
                    const collectionsMap = convertCollectionsSnapshotToMap(snapshot);
                    dispatch(fetchCollectionsSuccess(collectionsMap));
                }
            )
            .catch(error => dispatch(fetchCollectionsFailure(error.message)))
    }
} */