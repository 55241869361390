// Import combineReducers() from redux
import { combineReducers } from "redux";
// Reducers
import newsReducer from "./news/news.reducer";
import menuDropdownReducer from "./menu-dropdown/menu-dropdown.reducer";

const rootReducer = combineReducers({
  newsReducer: newsReducer,
  menuDropdownReducer: menuDropdownReducer
});

export default rootReducer;
