import { GlobalStyle } from "./global";
import { lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import Header from "./components/header/header.component";
import Footer from "./components/footer/footer.component";
import Loader from "./components/with-spinner/loader.jsx";
import ErrorBoundary from "./components/error-boundary/error-boundary.component";
import ToTopButton from "./components/to-top-button/to-top-button.component";
import HelmetComponent from "./Helmet";

const HomePage = lazy(() => import("./pages/home-page/home-page.component"));
const AboutPage = lazy(() => import("./pages/about/about.component"));
const NoMatch404 = lazy(() => import("./pages/404/NoMatch.component"));
const ContactPage = lazy(() => import("./pages/contact/contact.component"));
const NewsPage = lazy(() => import("./pages/news/news.component"));
const SearchResultsPage = lazy(() =>
  import("./pages/search-results/search-results.components")
);
const SearchPage = lazy(() => import("./pages/search/search.component"));
const AdminPageIn = lazy(() => import("./pages/admin/admin-page.component"));
const AdminPage = lazy(() => import("./pages/admin/admin.component"));
const NewsOne = lazy(() => import("./pages/news-one/news-one.component"));

function App() {
  return (
    <>
      <HelmetComponent />
      <GlobalStyle />
      <Header />
      <ErrorBoundary>
        <Suspense fallback={<Loader />}>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/category/:newsFilter" element={<NewsPage />} />
            <Route path="/category" element={<NoMatch404 />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/search/results" element={<SearchResultsPage />} />
            <Route path="/search" element={<SearchPage />} />
            <Route path="/news/:id" element={<NewsOne />} />
            <Route path="/admin-page/*" element={<AdminPageIn />} />
            <Route path="/admin" element={<AdminPage />} />
            <Route path="/*" element={<NoMatch404 />} />
          </Routes>
        </Suspense>
      </ErrorBoundary>
      <Footer />
      <ToTopButton />
    </>
  );
}

export default App;
