import { BubblyButton } from './custom-button.styles';

function CustomButton({ functions, ...otherProps }) {

    const animateButton = () => {
        // Props Functions
        if (functions) {
            functions()
        }
    };

    return (
        <BubblyButton onClick={animateButton} {...otherProps} disabled={otherProps.disabled}>
            {otherProps.children}
        </BubblyButton>
    );
}

export default CustomButton;