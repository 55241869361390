import styled from "styled-components";
import { globalContainer } from "../../global";
import { Link } from "react-router-dom";

export const FooterDiv = styled.div`
  padding: 20px 0;
  background-color: #fff;
`;

export const Container = styled.div`
  ${globalContainer};
  margin: 0 auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const FooterLinks = styled.div`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 767px) {
    flex-direction: column;
    gap: 10px;
  }
`;

export const FooterLink = styled(Link)`
  margin: 0 15px;
  text-decoration: none;
  font-family: "Nunito", sans-serif;
  color: #9babb6;
  font-size: 13px;
  transition: 0.2s linear;
  &:hover {
    color: #7f7f7f;
  }
`;

export const Copyright = styled.div`
  margin-top: 10px;
  font-size: 14px;
  font-weight: 300;
  color: #9babb6;
`;