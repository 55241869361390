import React from "react";
import { SpinnerContainer, SpinnerOverlay } from "./with-spinner.styles";

const Loader = () => {
    return (
        <SpinnerOverlay>
            <SpinnerContainer />
        </SpinnerOverlay>
    )
}

export default Loader;