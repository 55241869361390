import {redColor, additionalRedColor} from '../../global';
import styled, { css } from "styled-components";

export const BackToTopButton = styled.button`
    display: inline-block;
    background-color: ${redColor};
    width: 40px;
    height: 40px;
    text-align: center;
    border-radius: 4px;
    border: none;
    position: fixed;
    bottom: 30px;
    right: 30px;
    transition: background-color .3s,
        opacity .5s, visibility .5s, transform .3s, box-shadow .3s;
    z-index: 99;
    outline: none;
    padding: 10px;
    box-sizing: border-box;
    opacity: 0;
    visibility: hidden;
    box-shadow: 0 0 10px #FF8E8F, -0 -0 10px #FF8E8F;

    svg {
        width: 80%;

        path {
            fill: #fff;
        }
    }

    ${({ show }) => {
        return show ?
            css`
                visibility: visible;
                opacity: 0.75;
            `
            : null
    }}

    &:hover {
        cursor: pointer;
        opacity: 1;
        transform: translateY(-5px);
        background-color: ${additionalRedColor};
    }
`;