import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { redColor } from "../../global";

export const RightDesktop = styled.div`
  display: flex;
  align-items: center;
  button {
    font-family: "Nunito", sans-serif;
    font-size: 15px;
    font-weight: 700;
    padding: 7px 15px;
    margin-left: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
    span {
      display: block;
      width: 20px;
      height: 20px;
      img {
        width: 100%;
      }
    }
  }
  ${({ inCollapse }) =>
    inCollapse
      ? css`
          @media screen and (max-width: 575px) {
            display: none;
          }
        `
      : css`
          @media screen and (min-width: 576px) {
            display: none;
          }
          margin-top: 10px;
          flex-direction: column;
          gap: 20px;
          button {
            margin: 0;
          }
        `}
`;

export const SearchLink = styled(Link)`
  margin-right: ${({ inside }) => (inside === "true" ? "0" : "15px")};
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  &:hover svg path {
    fill: ${redColor};
  }
  @media screen and (max-width: 575px) {
    width: 25px;
    height: 25px;
  }
  svg {
    width: 100%;
    height: 100%;
  }
  svg path {
    transition: 0.2s linear;
  }
`;
