import MenuDropdownActionTypes from "./menu-dropdown.types";

const INITIAL_STATE = {
  isOpen: false,
};

const menuDropdownReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case MenuDropdownActionTypes.TOGGLE:
      document.body.style.overflowY = `${state.isOpen ? "auto" : "hidden"}`;
      return {
        ...state,
        isOpen: !state.isOpen,
      };
    case MenuDropdownActionTypes.MAKE_FALSE:
      document.body.style.overflowY = "auto";
      return {
        ...state,
        isOpen: false,
      };
    default:
      return state;
  }
};

export default menuDropdownReducer;
