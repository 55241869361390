import {
  HeaderDiv,
  Container,
  Collapse,
  CollapseInside,
} from "./header.styles";
import HeaderLeft from "./header-left.component";
import HeaderRight from "./header-right.component";
import HeaderMobile from "./header-mobile.component";
import { useRef } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  offDropdownMenu,
  toggleDropdownMenu,
} from "../../redux/menu-dropdown/menu-dropdown.actions";
import { selectDropdownMenuOpened } from "../../redux/menu-dropdown/menu-dropdown.selectors";

function Header() {
  const opened = useSelector(selectDropdownMenuOpened);

  const dispatch = useDispatch();
  const toggleOpened = () => dispatch(toggleDropdownMenu);
  const makeFalse = () => dispatch(offDropdownMenu);

  const ref = useRef(null);

  return (
    <HeaderDiv ref={ref} id="header">
      <Container>
        <HeaderLeft />
        <HeaderRight inCollapse={true} />
        <HeaderMobile />
      </Container>
      <Collapse opened={opened} onClick={makeFalse} />
      <CollapseInside opened={opened} onClick={makeFalse}>
        <ul
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <li>
            <Link
              onClick={() => {
                toggleOpened();
                window.scrollTo({ top: 0, behavior: "smooth" });
              }}
              to="/category/yangiliklar"
            >
              Янгиликлар
            </Link>
          </li>
          <li>
            <Link
              onClick={() => {
                toggleOpened();
                window.scrollTo({ top: 0, behavior: "smooth" });
              }}
              to="/category/tahlillar"
            >
              Таҳлиллар
            </Link>
          </li>
          <li>
            <Link
              onClick={() => {
                toggleOpened();
                window.scrollTo({ top: 0, behavior: "smooth" });
              }}
              to="/category/jahon-yangiliklari"
            >
              Жаҳон янгиликлари
            </Link>
          </li>
          <li>
            <Link
              onClick={() => {
                toggleOpened();
                window.scrollTo({ top: 0, behavior: "smooth" });
              }}
              to="/about"
            >
              ОАВ ҳақида
            </Link>
          </li>
          <li>
            <Link
              onClick={() => {
                toggleOpened();
                window.scrollTo({ top: 0, behavior: "smooth" });
              }}
              to="/contact"
            >
              Алоқа
            </Link>
          </li>
          <li>
            <HeaderRight inCollapse={false} inside={true} />
          </li>
        </ul>
      </CollapseInside>
    </HeaderDiv>
  );
}

export default Header;
