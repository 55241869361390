import { css, createGlobalStyle } from "styled-components";

export const darkColor = "#1d1d1d";
export const redColor = "#db0001";
export const additionalRedColor = "#b11818";
export const lightRedColor = "#FF2223";

export function globalContainer() {
  return css`
    @media screen and (max-width: 1250px) {
      width: 96%;
    }

    @media screen and (min-width: 1251px) {
      width: 1230px;
    }
  `;
}

export const GlobalStyle = createGlobalStyle`
    /* Universal selector */
    * {
      margin: 0;
      padding: 0;
    }
    /* Style HTML */
    html {
        scroll-behavior: smooth;
    }
    /* Style Body */
    body {
        margin: 0;
        padding: 0;
        font-family: 'Roboto', sans-serif;
        background-color: #fff;
        position: relative;
        z-index: 0;
    }
    /* Style Scrollbar and Selection */
    
    ::-webkit-scrollbar {
        width: 8px;
        height: 10px;
    }
    ::-webkit-scrollbar-track {
        box-shadow: inset 1px 1px 2px #e9e9e9;
    }
    ::-webkit-scrollbar-thumb {
        background-color: #bfbfbf;
        border-radius: 20px;
        border: 2px solid transparent;
    }
    *::selection {
        background: #fff9c4;
        color: #000;
        text-shadow: none;
    }
`;
