import styled, { css } from "styled-components";
import { redColor, additionalRedColor } from './../../global';

const fuschia = redColor;
const buttonTextColor = '#fff';

const disabledCon = css`
    cursor: default;
    opacity: 0.5;
`;

export const BubblyButton = styled.button`
    font-family: 'Helvetica', 'Arial', sans-serif;
    font-size: 1rem;
    background-color: ${fuschia};
    color: ${buttonTextColor};
    border-radius: 2px;
    border: none;
    cursor: pointer;
    position: relative;
    z-index: 1;
    transition: transform ease-in 0.1s, box-shadow ease-in 0.25s;
    max-width: 300px;
    padding: 15px 30px;
    user-select: none;

    ${({ disabled }) => {
        if (disabled) {
            return disabledCon
        }
    }}

    &:hover {
        transition: ${({ disabled }) => {
            if (!disabled) { return 'background-color 0.25s' }
        }};
        background-color: ${({ disabled }) => {
            if (!disabled) { return additionalRedColor }
        }};
    }

    &:focus {
        outline: 0;
    }
`;