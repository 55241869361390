import {useEffect} from 'react';
import { NoMatch404Container, ForImg } from './NoMatch.styles';
import { ReactComponent as PageNotFound } from '../../assets/404.svg';
import CustomButton from '../../components/custom-button/custom-button.component';
import { useNavigate } from 'react-router-dom';

function NoMatch404({ noMatch }) {
    const navigate = useNavigate();

    const returnToHome = () => {
        navigate('/');
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        })
    }

    useEffect(()=>{
        document.title = 'Хатолик - Fininfo.uz'
    }, [])

    return (
        <NoMatch404Container>
            <ForImg>
                <PageNotFound />
            </ForImg>
            <CustomButton functions={returnToHome}>Бош саҳифага</CustomButton>
        </NoMatch404Container>
    );
}

export default NoMatch404;