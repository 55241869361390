import {
  FooterDiv,
  Container,
  FooterLinks,
  FooterLink,
  Copyright,
} from "./footer.styles";

function Footer() {
  return (
    <FooterDiv>
      <Container>
        <FooterLinks>
          <FooterLink
            to="/category/yangiliklar"
            onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
          >
            Янгиликлар
          </FooterLink>
          <FooterLink
            to="/category/tahlillar"
            onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
          >
            Таҳлиллар
          </FooterLink>
          <FooterLink
            to="/category/jahon-yangiliklari"
            onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
          >
            Жаҳон янгиликлари
          </FooterLink>
          <FooterLink
            to="/about"
            onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
          >
            ОАВ ҳақида
          </FooterLink>
          <FooterLink
            to="/contact"
            onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
          >
            Алоқа
          </FooterLink>
        </FooterLinks>
        <Copyright>
          © 2023 Fininfo.uz — Ўзбекистон ва жаҳон янгиликлари
        </Copyright>
      </Container>
    </FooterDiv>
  );
}

export default Footer;
