import { Link } from "react-router-dom";
import styled from "styled-components";
import { additionalRedColor } from "./../../global";

export const LeftSide = styled.div`
  display: flex;
  align-items: center;
  ul {
    margin-left: 55px;
    display: flex;
    align-items: center;
    list-style-type: none;
    gap: 10px;
    li {
      a {
        text-decoration: none;
        color: #1d1d1d;
        margin-right: 20px;
        font-size: 15px;
        line-height: 23.16px;
        letter-spacing: 0.4px;
        transition: 0.2s;
        font-weight: 700;
        &:hover {
          color: ${additionalRedColor};
        }
      }
    }
    @media screen and (max-width: 1024px) {
      display: none;
    }
  }
`;

export const Logo = styled(Link)`
  max-width: 130px;
  svg {
    width: 100%;
  }
  img {
    max-width: 100%;
    max-height: 35px;
  }
`;
