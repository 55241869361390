import { takeLatest, call, put, all } from 'redux-saga/effects';

import { fetchCollectionsSuccess, fetchCollectionsFailure } from './news.actions';

import NewsActionTypes from './news.types';

import axios from 'axios';
import { MAIN_URL } from './../../resources/constants';

export function* fetchCollectionsAsync() {
    try {
       /*  axios.get(`${MAIN_URL}/news/paging?page=0&size=20`).then(res=>{
            console.log(res)
        }) */
        yield put(fetchCollectionsSuccess())
    } catch (error) {
        yield put(fetchCollectionsFailure(error.message))
    }
}

export function* fetchCollectionsStart() {
    yield takeLatest(NewsActionTypes.FETCH_NEWS_START, fetchCollectionsAsync)
}

export function* newsSagas() {
    yield all([
        call(fetchCollectionsStart)
    ])
}