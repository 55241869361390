import React from "react";
import { Link } from "react-router-dom";
// import { ReactComponent as LogoImage } from "../../assets/logo.svg";
import logoPath from "../../assets/logo.png";
import { LeftSide, Logo } from "./header-left.styles";

function HeaderLeft(props) {
  return (
    <LeftSide>
      <Logo
        to="/"
        onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
      >
        {/* <LogoImage /> */}
        <img src={logoPath} alt="logo" />
      </Logo>
      <ul>
        <li>
          <Link
            to="/category/yangiliklar"
            onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
          >
            Янгиликлар
          </Link>
        </li>
        <li>
          <Link
            to="/category/tahlillar"
            onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
          >
            Таҳлиллар
          </Link>
        </li>
        <li>
          <Link
            to="/category/jahon-yangiliklari"
            onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
          >
            Жаҳон янгиликлари
          </Link>
        </li>
        <li>
          <Link
            to="/about"
            onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
          >
            ОАВ ҳақида
          </Link>
        </li>
        <li>
          <Link
            to="/contact"
            onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
          >
            Алоқа
          </Link>
        </li>
      </ul>
    </LeftSide>
  );
}

export default HeaderLeft;
