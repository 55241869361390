import styled from "styled-components";

export const Mobile = styled.div`
  @media screen and (min-width: 1025px) {
    display: none;
  }
`;

export const Burger = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  div {
    width: ${({ opened }) => (opened ? "80%" : "100%")};
    height: ${({ opened }) => (opened ? "80%" : "100%")};
  }
  cursor: pointer;
  img {
    width: 100%;
  }
  display: flex;
  z-index: 102;
`;
