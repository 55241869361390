import React from "react";
import { RightDesktop, SearchLink } from "./header-right.styles";
import { ReactComponent as SearchIcon } from "../../assets/icons/search.svg";
import CustomButton from "../custom-button/custom-button.component";
import plusIconPath from "../../assets/icons/send.png";
import { useDispatch } from "react-redux";
import { offDropdownMenu } from "../../redux/menu-dropdown/menu-dropdown.actions";

function HeaderRight({ inCollapse, inside }) {
  const dispatch = useDispatch();
  const makeFalse = () => dispatch(offDropdownMenu);

  return (
    <RightDesktop inCollapse={inCollapse}>
      <SearchLink
        onClick={() => {
          makeFalse();
          window.scrollTo({ top: 0, behavior: "smooth" });
        }}
        to="/search"
        inside={`${inside}`}
      >
        <SearchIcon />
      </SearchLink>
      <CustomButton
        onClick={() => {
          makeFalse();
          window.open("https://t.me/Bankers_uz_Bot");
        }}
      >
        <span>
          <img src={plusIconPath} alt="send news" />
        </span>{" "}
        Янгилик юбориш
      </CustomButton>
    </RightDesktop>
  );
}

export default HeaderRight;
