import styled, { css } from "styled-components";
import { additionalRedColor, globalContainer } from "../../global";

export const HeaderDiv = styled.div`
  font-family: "Nunito", sans-serif;
  background-color: #fff;
  padding: 15px 0;
  box-shadow: 0px 4px 10px 0px #00000024;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 100;
  transition: 0.2s linear;
  overflow-x: clip;
`;

export const Container = styled.div`
  ${globalContainer};
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Collapse = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  max-height: 100vh;
  overflow-y: auto;
  padding: 20px 5px 20px 15px;
  box-sizing: border-box;
  transform: translateY(100%);
  /* transform: ${({ opened }) =>
    opened ? "translate(0, 100%)" : "translate(120%, 100%)"}; */
  transition: 0.6s ease-in-out;
  visibility: hidden;
  opacity: 0;
  ${({ opened }) =>
    opened
      ? css`
          visibility: visible;
          opacity: 1;
        `
      : null}
`;

export const CollapseInside = styled.div`
  position: absolute;
  bottom: -20px;
  left: 0;
  z-index: -1;
  width: 100%;
  background-color: transparent;
  max-height: 100vh;
  overflow-y: auto;
  padding: 20px 5px 20px 15px;
  box-sizing: border-box;
  transform: translateY(100%);
  transition: 0.6s ease-in-out;
  visibility: hidden;
  opacity: 0;
  ${({ opened }) =>
    opened
      ? css`
          visibility: visible;
          opacity: 1;
        `
      : null}
  display: flex;
  justify-content: center;

  ul {
    padding: 20px 100px;
    box-sizing: border-box;
    max-width: 95%;
    border-radius: 5px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    list-style-type: none;
    text-align: center;
    li {
      margin: 10px 0;
      a {
        text-decoration: none;
        color: #1d1d1d;
        font-size: 18px;
        line-height: 23.16px;
        transition: 0.2s;
        font-weight: 700;
        &:hover {
          color: ${additionalRedColor};
        }
      }
    }

    @media screen and (max-width: 575px) {
      padding: 20px 50px;
    }
    @media screen and (max-width: 350px) {
      padding: 20px 30px;
    }
  }
`;
